import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>


        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="144.000000pt" height="31.000000pt" viewBox="0 0 1440.000000 310.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,310.000000) scale(0.100000,-0.100000)"
               fill={fill} stroke="none">
                <path d="M1553 2942 c-145 -52 -223 -192 -184 -329 l9 -32 -235 -137 c-160
-93 -240 -134 -248 -128 -112 82 -142 94 -225 94 -70 0 -165 -44 -203 -93 -50
-66 -61 -98 -61 -177 0 -126 62 -215 178 -259 l51 -19 0 -282 0 -282 -50 -19
c-68 -25 -115 -66 -150 -130 -25 -46 -29 -65 -29 -129 0 -86 20 -134 78 -192
51 -51 100 -72 181 -76 80 -4 118 8 187 61 l45 35 139 -80 c76 -44 185 -107
241 -139 l101 -60 -9 -32 c-63 -221 181 -417 388 -311 120 61 183 209 138 326
-7 18 -24 7 305 196 l174 100 35 -30 c49 -43 114 -68 178 -68 80 0 128 18 183
67 68 61 93 118 93 207 0 76 -13 112 -65 178 -29 37 -116 88 -150 88 -17 0
-18 17 -18 288 l0 288 44 12 c61 16 124 68 159 131 26 46 30 64 30 125 0 42
-7 86 -16 108 -24 56 -91 123 -149 148 -93 40 -234 14 -300 -57 -12 -13 -26
-23 -32 -23 -6 0 -114 61 -241 134 -183 107 -229 138 -226 153 2 10 4 50 5 88
1 108 -52 193 -148 240 -71 35 -137 40 -203 17z m184 -97 c111 -66 123 -219
23 -309 -86 -77 -223 -57 -290 43 -37 54 -35 152 2 209 59 89 169 112 265 57z
m-329 -477 c-31 -51 -102 -174 -158 -273 -56 -99 -104 -181 -105 -183 -1 -1
-31 15 -66 36 -35 21 -83 49 -106 62 -38 21 -42 26 -35 49 4 14 6 60 6 102
l-2 76 237 136 236 136 24 -24 24 -23 -55 -94z m682 5 l237 -138 -1 -80 c0
-44 2 -91 6 -105 7 -24 0 -30 -110 -95 -64 -38 -172 -102 -240 -143 l-123 -73
-43 39 c-24 22 -65 47 -94 56 l-52 18 0 277 0 278 42 12 c46 14 128 66 128 81
0 6 3 10 7 10 3 0 113 -62 243 -137z m-512 39 c9 -7 12 -71 10 -286 l-3 -278
-49 -19 c-27 -10 -64 -33 -82 -50 -18 -17 -36 -29 -41 -27 -4 3 -52 31 -105
62 l-97 57 31 52 c17 29 83 142 146 252 63 110 120 210 128 223 15 22 39 28
62 14z m-825 -95 c45 -19 105 -89 113 -132 37 -197 -190 -320 -331 -179 -117
117 -29 324 138 324 26 0 62 -6 80 -13z m1951 -21 c74 -56 102 -142 72 -219
-32 -85 -97 -130 -188 -130 -71 0 -125 32 -164 98 -22 39 -26 54 -22 104 5 70
33 116 93 156 35 23 48 26 106 23 54 -3 73 -9 103 -32z m-1717 -387 c48 -28
93 -56 100 -62 9 -9 -11 -52 -88 -187 -154 -268 -221 -380 -228 -380 -3 0 -16
3 -28 6 l-23 6 0 287 0 286 51 18 c27 10 64 31 82 47 18 16 35 30 39 30 3 0
46 -23 95 -51z m1411 -31 c-216 -376 -291 -504 -298 -512 -5 -5 -52 16 -110
50 -76 44 -99 62 -94 74 13 30 16 106 6 143 -10 34 -8 37 16 50 28 14 317 185
412 243 54 34 55 34 74 15 18 -19 18 -20 -6 -63z m130 -4 l22 -6 0 -287 0
-286 -51 -18 c-28 -10 -67 -33 -87 -52 l-37 -33 -97 57 c-54 32 -101 60 -104
63 -3 3 49 102 117 220 185 325 199 348 207 348 4 0 17 -3 30 -6z m-1245 -139
c76 -45 96 -62 91 -74 -4 -9 -9 -41 -11 -71 -13 -152 119 -290 279 -290 53 0
135 34 175 72 32 31 34 32 61 16 138 -79 172 -100 172 -107 0 -5 -27 -57 -61
-115 l-61 -106 -488 0 -488 0 -17 49 c-8 26 -32 67 -51 90 l-36 41 162 280
c89 154 165 278 169 276 3 -2 50 -29 104 -61z m425 20 c161 -68 161 -292 0
-360 -96 -40 -205 4 -249 100 -73 158 89 326 249 260z m524 -571 c76 -44 98
-61 98 -78 0 -45 -8 -47 -162 -44 l-146 3 51 88 c28 48 53 87 56 87 3 0 49
-25 103 -56z m-1464 9 c20 -11 51 -39 67 -62 26 -37 30 -51 30 -110 0 -77 -22
-120 -83 -162 -30 -20 -48 -24 -108 -24 -65 0 -76 3 -111 31 -21 16 -48 50
-60 74 -85 165 97 339 265 253z m1921 -4 c79 -44 118 -151 86 -234 -20 -54
-84 -112 -132 -121 -128 -24 -232 53 -241 177 -4 49 0 65 22 103 58 99 167
130 265 75z m-812 -216 c-2 -5 -34 -61 -71 -125 l-67 -117 -69 6 c-88 7 -142
-8 -207 -57 l-50 -38 -139 81 c-77 44 -148 85 -159 91 -145 83 -183 109 -179
122 2 8 6 21 9 29 4 13 65 15 470 15 257 0 464 -3 462 -7z m451 -28 c3 -29 -3
-33 -125 -103 -70 -40 -177 -102 -238 -137 -60 -36 -112 -65 -115 -64 -3 0
-13 11 -23 24 -18 25 -18 25 65 170 l82 145 176 -2 175 -3 3 -30z m-581 -321
c143 -113 87 -321 -94 -350 -60 -9 -142 30 -180 87 -24 36 -28 53 -28 108 0
54 5 72 25 101 49 70 92 91 177 87 52 -2 69 -8 100 -33z"/>
                <path d="M7328 2646 c-108 -23 -198 -104 -229 -204 -6 -20 -13 -103 -16 -184
l-5 -148 -104 0 -104 0 0 -50 0 -50 105 0 105 0 0 -490 0 -490 60 0 60 0 0
490 0 490 175 0 175 0 0 50 0 50 -175 0 -175 0 0 143 c0 137 1 146 28 198 20
41 38 60 72 79 41 23 56 25 168 25 l122 0 0 46 0 46 -46 7 c-68 9 -151 6 -216
-8z"/>
                <path d="M11230 2375 l0 -276 -37 8 c-63 12 -264 8 -323 -6 -127 -32 -231
-103 -286 -196 -103 -176 -109 -506 -12 -687 55 -104 151 -168 284 -190 113
-19 244 16 322 86 22 20 45 36 51 36 7 0 11 -22 11 -60 l0 -60 50 0 50 0 0
810 0 810 -55 0 -55 0 0 -275z m-22 -371 l22 -6 0 -364 0 -364 -41 -45 c-23
-25 -67 -57 -98 -72 -49 -25 -67 -28 -151 -28 -83 0 -101 3 -145 27 -65 34
-98 70 -130 139 -54 120 -57 373 -6 510 40 105 135 184 251 209 47 10 258 6
298 -6z"/>
                <path d="M6145 2591 c-75 -18 -115 -73 -115 -159 0 -85 59 -129 159 -120 99
10 143 54 149 151 3 52 0 66 -17 89 -33 41 -103 56 -176 39z"/>
                <path d="M3850 2180 c-156 -33 -304 -191 -374 -401 -106 -317 -54 -612 122
-687 72 -31 138 -37 212 -18 74 19 117 44 184 106 l49 45 -6 -72 -6 -73 109 0
108 0 11 48 c14 60 221 1034 221 1039 0 2 -50 2 -111 1 l-112 -3 -26 -68 c-14
-37 -29 -66 -32 -65 -4 2 -22 23 -41 48 -62 82 -191 124 -308 100z m196 -249
c46 -28 64 -76 64 -168 -1 -273 -159 -509 -309 -460 -73 24 -101 112 -81 255
24 179 93 317 185 374 39 23 102 23 141 -1z"/>
                <path d="M5239 2177 c-68 -19 -130 -60 -187 -122 -28 -30 -53 -55 -57 -55 -3
0 -4 15 -1 33 3 17 8 56 11 85 l6 52 -110 0 c-105 0 -109 -1 -115 -23 -11 -45
-326 -1530 -326 -1538 0 -5 64 -9 143 -9 l142 0 28 133 c48 225 76 380 77 422
1 68 13 73 49 21 80 -116 226 -145 377 -75 79 36 191 157 242 260 129 260 132
589 8 736 -58 69 -193 106 -287 80z m29 -243 c42 -29 56 -72 55 -169 -1 -184
-78 -376 -177 -443 -43 -29 -113 -33 -151 -8 -86 57 -87 282 -1 462 34 72 98
147 139 164 36 14 111 11 135 -6z"/>
                <path d="M5971 2123 c-12 -54 -221 -1035 -221 -1040 0 -2 65 -3 145 -3 80 0
145 3 145 6 0 9 220 1046 226 1067 5 16 -6 17 -139 17 l-145 0 -11 -47z"/>
                <path d="M7875 2109 c-38 -5 -92 -14 -120 -21 l-50 -13 -3 -54 c-3 -46 -1 -53
13 -47 115 44 341 56 426 23 93 -36 126 -97 136 -251 l6 -104 -39 -6 c-21 -3
-104 -13 -183 -22 -217 -23 -322 -64 -381 -145 -55 -76 -63 -205 -19 -296 86
-179 400 -203 572 -44 l44 41 7 -31 c3 -17 6 -49 6 -70 l0 -39 51 0 50 0 -3
418 c-4 470 -6 483 -78 564 -79 90 -225 122 -435 97z m403 -699 l3 -143 -51
-48 c-110 -103 -310 -130 -413 -57 -66 47 -87 167 -44 249 25 47 73 81 142
100 40 11 332 48 350 44 6 -1 11 -60 13 -145z"/>
                <path d="M9034 2111 c-204 -33 -323 -141 -371 -338 -22 -90 -22 -311 0 -409
42 -192 157 -305 342 -334 81 -13 199 -5 289 19 l61 16 3 49 c3 47 2 48 -20
42 -283 -77 -469 -23 -536 156 -29 79 -46 216 -38 319 17 227 87 331 251 373
88 24 236 19 328 -10 15 -5 17 2 17 44 l0 50 -43 10 c-60 15 -222 22 -283 13z"/>
                <path d="M9735 2109 c-38 -5 -92 -14 -120 -21 l-50 -13 -3 -54 c-3 -46 -1 -53
13 -47 115 44 341 56 426 23 93 -36 126 -97 136 -251 l6 -104 -39 -6 c-21 -3
-104 -13 -183 -22 -217 -23 -322 -64 -381 -145 -55 -76 -63 -205 -19 -296 86
-179 400 -203 572 -44 l44 41 7 -31 c3 -17 6 -49 6 -70 l0 -39 51 0 50 0 -3
418 c-4 470 -6 483 -78 564 -79 90 -225 122 -435 97z m403 -699 l3 -143 -51
-48 c-110 -103 -310 -130 -413 -57 -66 47 -87 167 -44 249 25 47 73 81 142
100 40 11 332 48 350 44 6 -1 11 -60 13 -145z"/>
                <path d="M11932 2109 c-116 -22 -217 -100 -265 -202 -46 -96 -60 -177 -60
-337 1 -217 38 -337 135 -431 91 -88 251 -129 428 -110 58 6 126 17 150 24 44
13 45 13 48 60 2 26 2 47 0 47 -2 0 -46 -9 -98 -20 -218 -45 -388 -8 -469 102
-37 51 -68 143 -77 233 l-7 65 352 0 351 0 0 79 c0 276 -114 453 -315 490 -67
12 -111 13 -173 0z m235 -115 c84 -52 125 -139 139 -291 l7 -73 -298 0 -298 0
7 71 c18 178 83 280 206 320 14 5 62 7 107 6 70 -3 88 -7 130 -33z"/>
            </g>
        </svg>



    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
