import React from 'react';

import Card from '@bit/semantic-org.semantic-ui-react.card'
import Button from '@bit/semantic-org.semantic-ui-react.button'
import Image from '@bit/semantic-org.semantic-ui-react.image'
import Icon from '@bit/semantic-org.semantic-ui-react.icon'
import Label from '@bit/semantic-org.semantic-ui-react.label'
import Grid from '@bit/semantic-org.semantic-ui-react.grid'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import {graphql, Link, useStaticQuery} from "gatsby";

function shorten(str, maxLen, separator = ' ') {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
}

const siteQuery = graphql`
    {
        allImplementedApisJson(sort: {fields: followers, order: DESC}, limit: 20) {
            edges {
                node {
                    name
                    description
                    followers
                    id
                    image
                    providerAddress
                    payment
                    score
                    category
                }
            }
        }
    }
`;

const CardsList = () => {
    const results = useStaticQuery(siteQuery);
    const allApiListJson = results.allImplementedApisJson.edges;
    // console.log(JSON.stringify(allApiListJson, null, 2))

    return (
        <>
            { allApiListJson.map(api => {
                const path = require('../../../content/apis/implemented/logos/' + api.node.image);
                let requests = (1 + Math.floor(Math.random() * 10)) * api.node.priority;
                return (
                <>

                    <Card color='yellow' key={api.node.id}>
                        <Card.Content>
                            <Link to={"/" + api.node.id}>
                                <Image floated='right' size='tiny' alt={api.node.name} src={path}/>
                            </Link>
                            <Card.Header><Link to={"/" + api.node.id}>{api.node.name}</Link></Card.Header>
                            <Card.Meta>
                                <Link to={"/" + api.node.id}>
                                    <b>{shorten(api.node.description,300, " ")}</b>
                                </Link>
                            </Card.Meta>
                        </Card.Content>


                        <Card.Content extra>

                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column >
                                        <strong>{api.node.category}</strong>
                                    </Grid.Column>
                                    <Grid.Column only='computer mobile'>
                                        <Button as='div' labelPosition='right'>
                                            <Button color='red' onClick={ e => {
                                                // To stop the page reloading
                                                e.preventDefault();
                                                // Lets track that custom click
                                                trackCustomEvent({
                                                    // string - required - The object that was interacted with (e.g.video)
                                                    category: "Vote Button",
                                                    // string - required - Type of interaction (e.g. 'play')
                                                    action: "Click",
                                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                                    label: "Voting Campaign",
                                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                                    value: "1"
                                                });
                                                requests = requests + 1;
                                            }}>
                                                <Icon name='add' />
                                                Like
                                            </Button>
                                            <Label as='a' basic color='red' pointing='left'>
                                                {api.node.followers}
                                            </Label>
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                    </Card>
                </>
            )})}
        </>
    )
}


const ImplementedApis: React.FC = () => {
    return (
        <div>
            <h1>Public GraphQL APIs</h1>
            <br/>

            <Card.Group stackable itemsPerRow={4}>
                <CardsList/>
            </Card.Group>
            <br/>
        </div>
    )
};


export default ImplementedApis;
